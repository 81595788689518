import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Logo from "../../Assets/Login/smallLogo.png";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import LayoutSuspensePage from "../../Layouts/LayoutSuspensePage";

export function SuspensePage() {
  const [loading, setLoading] = useState(false);
  const [circle, setCircle] = useState(false);
  loading &&
    setTimeout(() => {
      setCircle(true);
    }, 700);

  const style = loading && {
    zoom: {
      transform: "scale(15)",
    },
  };
  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "93vh",
        position: "relative",
      }}
    >
      <Grid
        component="img"
        style={style.zoom}
        src={Logo}
        sx={{
          transition: "all 1s ease-in-out",

          width: "0.3vw",
          position: "absolute",
        }}
      />
      {circle && (
        <CircularProgress size="8vw" thickness={1.7}></CircularProgress>
      )}
    </Box>
  );
}

export default LayoutSuspensePage;
