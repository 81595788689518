import { MainApiUrl } from "../Imports/MainApiUrl";

export const ImageResponse = (ApiData) => {
  var config = {
    method: ApiData.method,
    headers: {
      Authorization: `Bearer ${ApiData.auth}`,
      "content-type": "application/json",
    },
  };

  return fetch(`${MainApiUrl}${ApiData.endPoint}`, config)
    .then(async (response) => {
      if (response.status === 200 || response.status === 201) {
        return response.blob();
      } else if (response.status === 401) {
        return {
          status_code: response.status,
          res: false,
          out: true,
        };
      } else {
        return { status_code: response.status, res: false };
      }
    })
    .then((blob, data) => {
      var MainData = {};
      if (!blob.status_code) {
        MainData = {
          data: blob,
          status_code: 200,
        };
        return MainData;
      } else {
        MainData = {
          ...blob,
          res: false,
        };
        return MainData;
      }
    })
    .catch((error) => {
      if (error.response && error?.code !== "ERR_NETWORK") {
        return {
          ...error?.response?.data,
          status_code: error?.response?.status,
        };
      } else if (error?.code === "ERR_NETWORK") {
        return { message: error?.message, status_code: error?.code };
      } else {
        return { message: "uncaught error", status_code: 499 };
      }
    });
};
