import Cookies from "js-cookie";
import { AllCookiesName } from "../Imports/CookiesName";

export const LocalTokenName = process.env.REACT_APP_TOKEN_STORAGE_NAME;
export var MainToken = {
  main: "",
  rem: false,
  refresh: "",
};

export const UpdateToken = (a) => {
  MainToken = { ...MainToken, ...a };
  if (MainToken?.main && MainToken?.refresh) StoreLocal(MainToken);
};

const StoreLocal = (a) => {
  Cookies.set(AllCookiesName.refreshToken, a.refresh, {
    expires: a.rem ? 365 : null,
    sameSite: "strict",
  });
  Cookies.set(AllCookiesName.accessToken, a.main, {
    sameSite: "strict",
  });
};

export const ExportLocalData = () => {
  let refresh = Cookies.get(AllCookiesName.refreshToken);
  let main = Cookies.get(AllCookiesName.accessToken);
  let remember = Cookies.get(AllCookiesName.remember);
  let token = null;
  if (!!refresh && !!main)
    token = {
      main,
      rem: !!(remember === "true"),
      refresh,
    };
  if (token === null || token === undefined) return undefined;
  UpdateToken(token);
  return token;
};

export const DeleteLocal = () => {
  MainToken = {
    main: "",
    rem: false,
    refresh: "",
  };
  localStorage.removeItem(LocalTokenName);
  sessionStorage.removeItem(LocalTokenName);
};
