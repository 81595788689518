import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";
import LanguageDetector from "i18next-browser-languagedetector";
import { AllLang } from "./Language";

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled:
        process.env.REACT_APP_PHRASE_ENABLE === "true" ? true : false,
      projectId: process.env.REACT_APP_PHRASE_KEY,
      prefix: "{{__",
      suffix: "__}}",
      baseUrl: "https://api.phrase.com/",
    })
  )
  .init({
    supportedLngs: Object.keys(AllLang),
    debug: process.env.REACT_APP_PHRASE_DEBUG === "true" ? true : false,
    fallbackLng: process.env.REACT_APP_PHRASE_FALLBACK_LANG,
    interpolation: {
      escapeValue: false,
    },
    postProcess: ["phraseInContextEditor"],
  });

export default i18next;
