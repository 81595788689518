import { createSlice } from "@reduxjs/toolkit";
import { UserRole } from "../Dictionary";

const initialState = {
  user: {},
  type: "",
  typeUser: {},
  profiles: [],
  applicant: "",
  visitor: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    UserSignUp: (state, action) => {
      state.user = action.payload;
      state.applicant =
        action.payload?.rol == UserRole.applicant ? state.applicant : "";
    },
    UserLogOut: (state) => {
      state.user = initialState.user;
      state.type = initialState.type;
      state.typeUser = initialState.typeUser;
      state.profiles = initialState.profiles;
      state.visitor = initialState.visitor;
    },
    UserChangeUserType: (state, action) => {
      state.type = action.payload;
    },
    UserTypeAllData: (state, action) => {
      state.typeUser = action.payload;
    },
    adminUidData: (state, action) => {
      state.user["schoolUid"] = action.payload;
    },
    PartnerUidData: (state, action) => {
      state.user["partnerUid"] = action.payload;
    },
    UserMultipleProfiles: (state, action) => {
      state.profiles = action.payload;
    },
    UserImageHandler: (state, action) => {
      state.user = { ...state.user, image: action.payload };
    },
    UserApplicantHandler: (state, action) => {
      state.applicant = action.payload;
    },
    UserVisistorHandler: (state, action) => {
      state.visitor = action.payload;
    },
  },
});

export default usersSlice.reducer;
export const {
  UserSignUp,
  UserLogOut,
  UserChangeUserType,
  UserTypeAllData,
  adminUidData,
  PartnerUidData,
  UserMultipleProfiles,
  UserImageHandler,
  UserApplicantHandler,
  UserVisistorHandler,
} = usersSlice.actions;
