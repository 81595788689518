import React from "react";
import Box from "@mui/material/Box";
import { useLottie } from "lottie-react";
import LoadingAnimation from "../Assets/Common/loadingAnimation";
import { Typography } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";
// import Logo from "../Assets/Login/smallLogo.png";
// import { Grid } from "@mui/material";

// circular loader with logo
// export function CircularLoader({ height }) {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         flexDirection: "column",
//         height: height ?? "93vh",
//         position: "relative",
//       }}
//     >
//       <Grid
//         component="img"
//         src={Logo}
//         sx={{
//           width: "3vw",
//           height: "3vw",
//           position: "absolute",
//         }}
//       />
//       <CircularProgress
//         color="primary"
//         size="5vw"
//         thickness={1.9}
//       ></CircularProgress>
//     </Box>
//   );
// }

export default function LayoutSuspensePage(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    style: {
      height: 180,
      width: 180,
    },
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(defaultOptions);
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "90vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...props?.sx,
        }}
      >
        {View}
      </Box>
      <Typography variant="h5">Loading...</Typography>
    </Box>
  );
}
