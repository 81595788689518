import React, { lazy, useLayoutEffect } from "react";
import { Grid } from "@mui/material";
const MyEmailVerification = lazy(() => import("../Common/MyEmailVerification"));
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEmailVerificationMutation } from "../PartnerApplication/PublicApplicationQuery";

const EmailVerify = () => {
  // const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();

  //rtk query
  const [verifyEmail, { isLoading, isSuccess, isError, error }] =
    useEmailVerificationMutation();

  const handleVerifyEmail = async (token, email) => {
    const res = await verifyEmail({
      url: `users/partner-verify-email?userUid=${token}&email=${email}`,
      method: "POST",
      // msz: false,
    });
    if (res?.data?.status_code === 200) {
      // handleSetTimer();
    } else if (
      res?.error?.status_code === 400 &&
      res?.error?.message === "Email Already Verified!..."
    ) {
      navigate("/home");
    }
  };

  useLayoutEffect(() => {
    let token = searchParam.getAll("token");
    let email = searchParam.getAll("email");
    if (token?.length) {
      handleVerifyEmail(token[0], email[0]);
    }
  }, []);

  return (
    <Grid>
      <MyEmailVerification
        emailLoading={isLoading || !isError}
        errorMessage={error?.message}
        success={isSuccess}
        // success={true}
        // subTitle={`Navigating to dashboard in ${time}`}
      />
    </Grid>
  );
};

export default EmailVerify;
