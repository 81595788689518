import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  documentData: [],
  maritalFields: {},
  qualificationFields: {},
  experienceFields: {},
  propsArray: [],
  timestamp: 0,
};

const ApplicantSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    SaveApplicant: (state, action) => {
      state.formData = action.payload.formData;
      state.documentData = action.payload.documentData;
      state.maritalFields = action.payload.maritalFields;
      state.experienceFields = action.payload.experienceFields;
      state.qualificationFields = action.payload.qualificationFields;
      state.propsArray = action.payload.propsArray;
      state.timestamp = action.payload.timestamp;
    },
    DeleteApplicant: (state) => {
      state.documentData = initialState.documentData;
      state.maritalFields = initialState.maritalFields;
      state.experienceFields = initialState.experienceFields;
      state.formData = initialState.formData;
      state.propsArray = initialState.propsArray;
      state.qualificationFields = initialState.qualificationFields;
      state.timestamp = initialState.timestamp;
    },
  },
});

export default ApplicantSlice.reducer;

export const { SaveApplicant, DeleteApplicant } = ApplicantSlice.actions;
