import axios from "axios";
import ToastHandler from "./ToastHandler";
import { MainApiUrl } from "../Imports/MainApiUrl";
import { ImageResponse } from "./ImageResponseHandler";
import { TrimAllBodyData } from "./ConvertFormData";
import { UpdateToken } from "./MainToken";

let showErrorCode =
  process.env.REACT_APP_SHOW_ERROR_CODE === "true" ? true : false;

const ApiCallHandler = (ApiData) => {
  if (ApiData.image) return ImageResponse(ApiData);
  var config = {
    method: ApiData.method,
    url: `${MainApiUrl}${encodeURI(ApiData.endPoint)}`,
    headers: {
      "Content-Type": ApiData?.contentType || "application/json",
      Authorization: ApiData.skipToken ? "" : `Bearer ${ApiData.auth}`,
      "Academic-Session": ApiData.academicSession,
      recaptcha: ApiData.recaptcha ?? "",
    },
    data: TrimAllBodyData(ApiData.data),
    params: ApiData.params,
    onUploadProgress: ({ loaded, total }) => {
      ApiData?.upPercent &&
        ApiData.upPercent(Math.floor((loaded * 100) / total));
    },
    onDownloadProgress: ({ loaded, total }) => {
      ApiData?.doPercent &&
        ApiData.doPercent(Math.floor((loaded * 100) / total));
    },
  };

  return axios(config)
    .then((response) => {
      return { ...response.data, status_code: response.status };
    })
    .catch((error) => {
      if (error.response && error?.code !== "ERR_NETWORK") {
        return {
          ...error?.response?.data,
          status_code: error?.response?.status,
        };
      } else if (error?.code === "ERR_NETWORK") {
        return { message: error?.message, status_code: error?.code };
      } else if (error.response) {
        return { ...error.response };
      } else {
        return {
          ...error?.response,
          message: "uncaught error",
          status_code: 499,
        };
      }
    });
};

export const ApiResponseHandler = async (ApiData) => {
  return ApiCallHandler(ApiData)
    .then(async (response) => {
      var MainData = {};
      if (response.status_code === 200 || response.status_code === 201) {
        if (ApiData.msz) {
          ToastHandler("sus", response?.message);
        }
        MainData = {
          data: response,
          status: true,
          res: true,
        };
        return MainData;
      } else if (
        response.status_code === 400 ||
        response.status_code === 401 ||
        response.status_code === 402
      ) {
        MainData = {
          status: false,
          res: false,
          data: response,
        };
        !ApiData.skipError && ToastHandler("warn", response?.message);
        return MainData;
      } else if (response.status_code === 403) {
        const newAccess = await ApiCallHandler(ApiData.refreshData);
        if (newAccess.status_code === 200) {
          UpdateToken({ main: newAccess.accessToken });
          const ApiData2 = { ...ApiData, auth: newAccess.accessToken };
          const response2 = await ApiCallHandler(ApiData2);
          if (response2.status_code === 200 || response2.status_code === 201) {
            if (ApiData2.msz) {
              ToastHandler("sus", response2?.message);
            }
            MainData = {
              data: response2,
              status: true,
              res: true,
            };
            return MainData;
          } else if (response2.status_code === 403) {
            !ApiData.skipError && ToastHandler("warn", response2?.message);
            return {
              status: false,
              res: false,
              out: true,
              data: response2,
            };
          } else {
            !ApiData.skipError && ToastHandler("warn", response2?.message);
            return {
              status: false,
              res: false,
              data: response2,
            };
          }
        } else if (newAccess.status_code === 401) {
          ToastHandler("warn", newAccess?.message);
          return {
            status: false,
            res: false,
            out: true,
            data: newAccess,
          };
        } else
          return {
            status: false,
            res: false,
            out: true,
            data: newAccess,
          };
      } else if (response.status_code === 499) {
        !ApiData.skipError &&
          ToastHandler(
            "warn",
            `${showErrorCode ? response.status_code + " " : ""}${
              response?.message
            }`
          );
        return {
          status: false,
          res: false,
          data: response,
        };
      } else {
        MainData = {
          status: false,
          res: false,
          data: response,
        };
        if (!(ApiData.image && response.status_code === 406))
          ToastHandler(
            "dan",
            `${showErrorCode ? response.status_code + " " : ""}${
              response?.message
            }`
          );
        return MainData;
      }
    })
    .catch((error) => {
      ToastHandler("dan", error?.response?.message || "API Error");
      return {
        status: false,
        res: false,
      };
    });
};
