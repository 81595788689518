export const ConvertFormData = (v) => {
  const form1 = new FormData();
  for (const key in v) {
    form1.append(key, v[key]);
  }
  return form1;
};

export const RemoveNullFromObj = (obj) => {
  if (typeof obj !== "object" || obj === null || Array.isArray(obj)) {
    return obj; // Return the input argument if it's not an object
  }

  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      obj[key] = ""; // Convert null or undefined value to an empty string
    }
  }
  return obj;
};

export const CorrectFormatAmount = (amount) => {
  return Number(amount).toLocaleString("en-In");
};

//convert obj to text that we can pass to navigate with param changes
export const ObjToParam = (v) => {
  var temp = "?";
  var i = 0;
  for (const key in v) {
    if (i) temp = temp + "&" + key + "=" + v[key];
    else temp = temp + key + "=" + v[key];
    i = i + 1;
  }
  return temp;
};

export const ParamToObj = (param) => {
  if (param) {
    const temp = {};
    param.forEach((value, key) => {
      temp[key] = value;
    });
    return temp;
  } else return {};
};

//convert all string with trim
export const TrimAllBodyData = (v) => {
  if (
    typeof v === "object" &&
    !(v instanceof FormData) &&
    !(v instanceof Array)
  ) {
    let temp = { ...v };
    for (var key in temp) {
      let a = temp[key];
      if (typeof a === "string") {
        a = a.trim();
        temp[key] = a;
      }
    }
    return temp;
  } else return v;
};

export const MultiApiCall = async (v) => {
  let MyArray = v?.params;
  if (Array.isArray(MyArray) && MyArray.length) {
    let MyFunction = v?.function;
    let MyHandler = v?.handler;
    let MyResPass = [];
    let MyResFail = [];
    if (MyFunction) {
      if (MyHandler) {
        for (let i = 0; i < MyArray.length; i++) {
          let MyObj = MyArray[i];
          const res = await MyFunction(MyHandler(MyObj));
          if (
            res?.data?.status_code === 200 ||
            res?.data?.status_code === 201 ||
            res?.status_code === 200 ||
            res?.status_code === 201
          ) {
            MyResPass.push({ res: res, obj: MyObj });
          } else {
            MyResFail.push({ res: res, obj: MyObj });
          }
        }
        v?.finalCall && v.finalCall();
        return {
          passed: MyResPass,
          failed: MyResFail,
          msz:
            MyResFail.length === 0
              ? "Successfully"
              : `${MyResFail.length} are failed`,
        };
      } else {
        return { msz: "handler is missing" };
      }
    } else {
      return { msz: "function is missing" };
    }
  } else {
    return { msz: "Invalid type/length of params" };
  }
};
