export const getDesignTheme = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            light: "#fcebb1", // Light peach (254, 228, 160)
            main: "#f69200", // Orange hue (246, 144, 0)
            dark: "#fcbb11", // Yellow (252, 187, 17)
            contrastText: "#fff",
          },
          secondary: {
            light: "#ffffff", // White (255, 255, 255)
            main: "#f4efe8", // Light grayish white (244, 239, 235)
            dark: "#000000", // Very dark green (0, 34, 28)
          },
          contras: {
            main: "#000000",
          },
          current: {
            main: "#ffffff",
          },
          working: {
            light: "#fcebb1", // Light peach (254, 228, 160)
            main: "#f69200", // Orange hue (246, 144, 0)
            dark: "#fcbb11", // Yellow (252, 187, 17)
            contrastText: "#fff",
          },
          disable: {
            light: "#888888",
            main: "#8888884d",
            dark: "#636678",
            contrastText: "#fff",
          },
          background: {
            default: "#ffffff",
            dark: "#2a3042",
            navBar: "#f69200", // Orange hue (246, 144, 0)
            sideBar: "#f4efe8", // Light grayish white (244, 239, 235)
            sideBar2: "#f5f0eb", // Slightly darker light grayish white
            card: "#fcbb11", // Yellow (252, 187, 17)
            card2: "#ffffff", // White (255, 255, 255)
            card3: "#f4efe8", // Light grayish white (244, 239, 235)
          },
          icon: {
            green: "#68B34E",
            greenlight: "#E5FFF1",
            dark: "#f69200",
            warning: "#FFA800",
            warninglight: "#fffbef",
            contrastIcon: "#fff",

            error: "#f46a6a",
            errorlight: "#FCE8EA",
            sidebar: "#f4efe8", // Light grayish white (244, 239, 235)
            sidebar1: "#ffffff", // White (255, 255, 255)
            fieldErr: "#F83630",
            blue: "#6b56f6",
            skyblue: "#4d7cf8",
          },
          text: {
            head: "#323139",
            normal: "#4d4d4d",
            primary: "#212121",
            secondary: "#878787",
            contrasPrimary: "#ffffff",
            contrasSecondary: "#ffffff88",
            sidebar: "#7c7c7c",
            dashboard: "#545454",
          },
          border: {
            main: "#f4efe8", // Light grayish white (244, 239, 235)
            primary: "#495057",
            scroll: "#666e7c",
            input: "#7443C4",
          },
          shadow: {
            navBar: "#12263f08",
          },
          color: {
            pink: {
              primary: "#FBE6F1",
              secondary: "#f5c2dc",
            },
            orange: { primary: "#fef2e2", secondary: "#ffd399" },
            // green: { primary: "#CDFFCE", secondary: "#94ff96" },
            yellow: { primary: "#fffce5", secondary: "#fff394" },
            purple: { primary: "#f0e2fe", secondary: "#cc99ff" },
            red: { primary: "#ffe5e5", secondary: "#ff9999" },
            blue: { primary: "#e2e9fe", secondary: "#adc2ff" },
            clayBlue: { primary: "#e3f3fc", secondary: "#94d8ff" },
            voilet: { primary: "#e2dffb", secondary: "#9f94ff" },
            grey: "#DFE4FF",
            green: { primary: "#069e10", secondary: "#94ff96" },
          },
          attendance: {
            light: "#93a5ff",
            present: "#3CCF4E",
            leave: "#EF5B0C",
            absent: "#C21010",
            sports: "#277BC0",
            medical: "#1CD6CE",
            "first half": "#F6A192",
            half: "#F6A192",
            "second half": "#EA6823",
            chip: "#00000014",
          },
          button: { main: "#008060", light: "#68B34E" },
          buttonText: { main: "#ffffff" },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#EA6823",
          },
          secondary: {
            main: "#000",
          },
          background: {
            default: "#222736",
          },
        }),
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  selected: {},
});
