import { apiSlice } from "../../../Redux/Api/ApiSlice";

const PublicAplicationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    SendOTP: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: url,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
    }),
    SendEmailVerificationLink: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: url,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
    }),
    OTPverification: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: url,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
    }),
    EmailVerification: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: url,
          body: body,
          method: method,
          msz: msz ?? false,
        };
      },
    }),
    PartnerRegistration: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: url,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
      invalidatesTags: ["partner-form"],
    }),
    GETPartnerform: builder.query({
      query: (url) => {
        return {
          url: `users/partner-user-form${url ? url : ""}`,
        };
      },
      providesTags: ["partner-form"],
    }),
    VerifyPan: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: `users/verify/panNo${url ? url : ""}`,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
      // invalidatesTags: ["partner-form"],
    }),
    VerifyGstNo: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: `users/verify/gstNo${url ? url : ""}`,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
      // invalidatesTags: ["partner-form"],
    }),
    GenerateAadhaarOTP: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: `users/aadhar/generate/otp${url ? url : ""}`,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
      // invalidatesTags: ["partner-form"],
    }),
    VerifyAadhaarOTP: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: `users/validate/aadhar/otp${url ? url : ""}`,
          body: body,
          method: method,
          msz: msz ?? true,
        };
      },
      // invalidatesTags: ["partner-form"],
    }),

    VerifiedDocumentData: builder.mutation({
      query: ({ url, body, method, msz }) => {
        return {
          url: `users/get/document/detail${url ? url : ""}`,
          body: body,
          method: method,
          msz: msz ?? false,
        };
      },
      // invalidatesTags: ["partner-form"],
    }),

    GetTransactionHistory: builder.query({
      query: (args) => {
        return {
          url: `users/registration/transaction${args ? args : ""}`,
        };
      },
    }),
  }),
});

export default PublicAplicationSlice;

export const {
  useGETPartnerformQuery,
  useSendOTPMutation,
  useSendEmailVerificationLinkMutation,
  useOTPverificationMutation,
  useEmailVerificationMutation,
  usePartnerRegistrationMutation,
  useVerifyPanMutation,
  useVerifyGstNoMutation,
  useVerifyAadhaarOTPMutation,
  useGenerateAadhaarOTPMutation,
  useVerifiedDocumentDataMutation,
  useGetTransactionHistoryQuery,
} = PublicAplicationSlice;
