import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import ToastHandler from "../Functions/ToastHandler";

var firebaseConfig = {
  apiKey: "AIzaSyBIb-fp_W-mH1PhR42yyXRMFY6excErHi4",
  authDomain: "quickcampus-f16c7.firebaseapp.com",
  projectId: "quickcampus-f16c7",
  storageBucket: "quickcampus-f16c7.appspot.com",
  messagingSenderId: "596778206460",
  appId: "1:596778206460:web:3efc112436f908cc888379",
  measurementId: "G-DD19Z95WFR",
};
let firebaseApp;
let messaging;
if (process.env.FIREBASE_STATUS === "true")
  firebaseApp = initializeApp(firebaseConfig);
if (firebaseApp) messaging = getMessaging(firebaseApp);

export const fetchToken = async () => {
  let check = await isSupported();
  if (check && firebaseApp) {
    let check2 = await Notification.requestPermission();
    if (check2)
      return getToken(messaging, {
        vapidKey:
          "BO-tjxSXFGW6yDoubtVK5T2TXHltKVkHw5n0Ni8yg3RWMLV9lg6_IFlCuh3yrIxWojCuHHn6LjlhHX7pmxLzM-c",
      })
        .then((currentToken) => {
          if (currentToken) {
            return {
              device_token: currentToken,
              device_id: "-1",
            };
          } else {
            return {
              device_token: null,
              device_id: "-1",
              permission: true,
            };
          }
        })
        .catch((err) => {
          return {
            device_token: null,
            device_id: "-1",
            failed: true,
          };
        });
  }
  return {
    device_token: null,
    device_id: "-1",
    failed: true,
  };
};

export const onMessageListener = () => {
  onMessage(messaging, (payload) => {
    console.log(payload);
    ToastHandler("sus", payload?.notification?.title);
  });
};
